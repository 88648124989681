import React from 'react'

import Layout from '../components/layout'
import Headline from '../elements/headline'
import Seo from '../components/seo'

const LegalPage = () => (
  <Layout>
    <Seo title="DSGVO / GDPR" />
    <section
      className="
        bg-blue-100
        flex
        flex-col
        items-center
        justify-center
        mt-16
        px-16
        py-48
        text-center
        w-full
      "
    >
      <p
        className="
          font-mono
          text-blue-900
        "
      >Last Modified: 2021-12-03</p>
      <Headline level={1}>Legal / Impressum</Headline>
      <Headline level={3}>Angaben gemäß § 5 TMG</Headline>
    </section>
    <section
      className="
        flex
        flex-wrap
        items-stretch
        justify-center
        mb-10
        px-4
        md:px-0
      "
    >
      <div
        className="
          markdown
          ml-auto
          mr-auto
          mt-16
          max-w-2xl
        "
      >
        <p>podlove.org</p>
        <p>Vertreten durch:</p>
        <p>Eric Teubert<br />Alexander Heimbuch<br />Michaela Lehr</p>
        <p>E-Mail: podlove@podlove.org</p>
        <p>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</p>
        <p>Eric Teubert<br />Hafenstr. 5C<br />04179 Leipzig</p>
        <h2>Haftungsausschluss:</h2>
        <h3>Haftung für Links</h3>
        <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
      </div>
    </section>
  </Layout>
)

export default LegalPage
